<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="grocery_nonMember">

        <div class="form_box">
          <form @submit.prevent>

            <div
              v-if="$route.query.member !== 't'"
              class="w1300 grocery_container"
            >

              <div class="main_title_box" v-if="!hasToken">
                <h3 class="main_title txt-bold">非會員報名</h3>
                <div class="img_box">
                  <img src="@/statics/img/index/icon_title2.svg" alt="">
                </div>
              </div>

              <!-- 切換頁籤 -->
              <!-- <div class="switch_toggle container">
                <ul>
                  <li
                    v-for="(switchItem) in switchList"
                    :key="switchItem.name"
                    :class="{ active: switchItem.name === tableName }"
                    @click="switchTable(switchItem.name)"
                  >
                    <h4 class="txt-bold">{{ switchItem.name }}</h4>
                  </li>
                </ul>
              </div> -->

              <!-- 非會員報名 -->
              <div class="form_switch_box container">

                <!-- 首次報名 -->
                <!-- <section class="form_area register_first" v-if="tableName === '首次報名'">

                  <div class="form_interactive">
                    <div class="half">
                      <TextInput
                        class="w50"
                        v-model.trim="user.realname"
                        :item="inputFormat.nameInput"
                        :rules="[
                          val => !!val || '必填欄位！',
                          val => val.length > 1 || '長度錯誤!',
                        ]"
                      />
                      <Radio
                        class="w50"
                        v-model="user.jobs.title"
                        :item="inputFormat.jobsTitleRadio"
                      />
                    </div>
                    <div class="half">
                      <TextInput
                        class="w100"
                        v-model.trim="user.id_number"
                        :item="inputFormat.idNumberInput"
                        :rules="[
                          val => !!val || '必填欄位！',
                          [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                        ]"
                        :maxlength="10"
                      />
                    </div>
                    <TextInput
                      class="w50"
                      v-model.trim="user.phone"
                      :item="inputFormat.phoneInput"
                      :rules="[
                        val => !!val || '必填欄位！',
                        [/^09\d{8}$/, '手機格式錯誤']
                      ]"
                      :maxlength="10"
                    />
                    <TextInput
                      class="w50"
                      v-model.trim="user.hospital_id"
                      :item="inputFormat.hospitalIdInput"
                      :rules="[
                        val => !!val || '必填欄位！',
                        val => val.length > 1 || '長度錯誤!',
                      ]"
                    />
                    <div class="select_container w100">
                      <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                      <div class="select_container-left err_message_box w50">
                        <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                        <select
                          v-model="user.memo.hospitalCity"
                          class="select_city address_top w33"
                          @change="resetHospitalSelect"
                        >
                          <option value="" selected>請選擇</option>
                          <option
                            v-for="(town, index) in hospitalTowns"
                            :key="index"
                            :value="town"
                          >{{ town }}
                          </option>
                        </select>
                        <select
                          v-model="user.memo.hospitalArea"
                          class="select_city address_top w33"
                          @change="updateHospitalZip"
                        >
                          <option value="" selected>請選擇</option>
                          <option
                            v-for="(area, index) in hospitalAreas"
                            :key="index"
                            :value="area"
                          >{{ area }}
                          </option>
                        </select>
                        <input
                          :value="user.memo.hospitalZipcode"
                          type="text"
                          readonly
                          class="address_top w33"
                          placeholder="郵遞區號"
                        >
                      </div>
                      <div class="select_container-right w50">
                        <TextInput
                          class="address_top"
                          v-model.trim="user.memo.hospitalAddr"
                          :item="inputFormat.hospitalAddrInput"
                        />
                      </div>
                    </div>
                  </div>

                </section> -->

                <!-- 已填過報名資料 -->
                <section class="form_area register_already">
                  <div
                    v-if="!hasToken && !idCheck"
                    class="form_input_box register_already_toggle register_already_input"
                  >
                    <div class="half">
                      <TextInput
                        class="w100"
                        v-model.trim="user.id_number"
                        :item="inputFormat.idNumberInput"
                        :rules="[
                          val => !!val || '必填欄位！',
                          [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                        ]"
                        :maxlength="10"
                      />
                    </div>
                    <div class="half half-btn">
                      <button
                        type="button"
                        class="register_already_btn btn"
                        @click="userIdVerify"
                      >送出</button>
                    </div>
                  </div>

                  <div
                    class="form_input_box register_already_toggle register_already_output"
                    v-if="showUserForm"
                  >

                    <div class="form_interactive">
                      <div class="half">
                        <TextInput
                          class="w50"
                          v-model.trim="user.realname"
                          :item="inputFormat.nameInput"
                          :rules="[
                            val => !!val || '必填欄位！',
                            val => val.length > 1 || '長度錯誤!',
                          ]"
                        />
                        <Radio
                          class="w50"
                          v-model="user.user_type"
                          :item="inputFormat.jobsTitleRadio"
                        />
                      </div>
                      <div class="half">
                        <TextInput
                          class="w100"
                          v-model.trim="user.id_number"
                          :item="inputFormat.idNumberInput"
                          :rules="[
                            val => !!val || '必填欄位！',
                            [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                          ]"
                          :maxlength="10"
                        />
                      </div>
                      <TextInput
                        class="w50"
                        v-model.trim="user.phone"
                        :item="inputFormat.phoneInput"
                        :rules="[
                          val => !!val || '必填欄位！',
                          [/^09\d{8}$/, '手機格式錯誤']
                        ]"
                        :maxlength="10"
                      />
                      <TextInput
                        class="w50"
                        v-model.trim="user.jobs.hospital_name"
                        :item="inputFormat.hospitalIdInput"
                        :rules="[
                          val => !!val || '必填欄位！',
                          val => val.length > 1 || '長度錯誤!',
                        ]"
                      />
                      <div class="select_container w100">
                        <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                        <div class="select_container-left err_message_box w50">
                          <span class="err_message txt-red small">
                            {{ hospitalAddressErrorMsg }}
                          </span>
                          <select
                            v-model="user.jobs.hospitalCity"
                            class="select_city address_top w33"
                            @change="resetHospitalSelect"
                          >
                            <option value="" selected>請選擇</option>
                            <option
                              v-for="town in hospitalTowns"
                              :key="town"
                              :value="town"
                            >{{ town }}
                            </option>
                          </select>
                          <select
                            v-model="user.jobs.hospitalArea"
                            class="select_city address_top w33"
                            @change="updateHospitalZip"
                          >
                            <option value="" selected>請選擇</option>
                            <option
                              v-for="area in hospitalAreas"
                              :key="area"
                              :value="area"
                            >{{ area }}
                            </option>
                          </select>
                          <input
                            :value="user.jobs.hospitalZipcode"
                            type="text"
                            readonly
                            class="address_top w33"
                            placeholder="郵遞區號"
                          >
                        </div>
                        <div class="select_container-right w50">
                          <TextInput
                            class="address_top"
                            v-model.trim="user.jobs.hospitalAddr"
                            :item="inputFormat.hospitalAddrInput"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form_submit center_position" v-if="showUserForm">
                      <button
                        type="button"
                        class="btn"
                        @click="userInfoSubmit"
                      >送出</button>
                    </div>

                  </div>
                </section>

              </div>

            </div>

            <!-- 課程購買明細 -->
            <section
              id="form_detail"
              class="register_online_courses_grocery"
              v-if="showPaymentDetail || hasToken"
            >
              <div class="w1300 grocery_container">

                <!-- 表單主標題 -->
                <div class="main_title_box">
                  <h3 class="main_title txt-bold">課程購買明細</h3>
                  <div class="img_box">
                    <img src="@/statics/img/index/icon_title2.svg" alt="">
                  </div>
                </div>

                <div class="form_input_table">

                  <!-- table area -->
                  <div class="table_box">
                    <table class="txt-center">
                      <thead>
                        <tr>
                          <th class="td-main txt-left">
                            <p>{{ tableHead1 }}</p>
                          </th>
                          <th class="min130">
                            <p>{{ tableHead2 }}</p>
                          </th>
                          <th class="min150">
                            <p>{{ tableHead3 }}</p>
                          </th>
                          <th class="min130">
                            <p>{{ tableHead4 }}</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="table_data">
                        <template v-for="tr in tableData">
                          <tr
                            class="tr_info"
                            :class="{ unavailable: isActive(tr) }"
                            :key="`data_info_${tr.uuid}`"
                          >
                            <td :data-title="tableHead1" class="td-main txt-left">
                              <p >{{ tr.name }}
                                <span
                                  class="txt-red"
                                  v-show="isActive(tr)"
                                >
                                  (身份別無法購買)
                                </span>
                              </p>
                            </td>
                            <td :data-title="tableHead2"><p>{{ tr.video_count }}</p></td>
                            <td :data-title="tableHead3" class="td-input">
                              <input type="text" placeholder="請輸入收據抬頭" v-model="tr.title">
                            </td>
                            <td :data-title="tableHead4" class="td-price">
                              <template v-if="tr.price">
                                <p :data-price="tr.price[level].price">
                                  <span v-show="!isActive(tr)">
                                    {{ tr.price[level].price }}</span>
                                  <span v-show="isActive(tr)">
                                    無法操作</span>
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr
                            :key="`data_input_${tr.uuid}`"
                            :class="{ unavailable: isActive(tr) }"
                          >
                            <td colspan="4" class="td-manipulation">
                              <div class="form_interactive">
                                <div
                                  class="fraction_radio"
                                  v-if="level * 1 === 1
                                  && (tr.fraction_a * 1 !== 0 || tr.fraction_b * 1 !== 0)"
                                >
                                  <RadioFraction
                                    :item="fractionRadio(tr)"
                                    @updateFraction="updateFraction"
                                    :required="!isActive(tr)"
                                  />
                                </div>
                                <div class="fraction_checkbox">
                                  <template v-if="isFractionExist(tr)">
                                    <CheckboxFraction
                                      :item="fractionCheckbox(tr)"
                                      @updateFraction="updateFraction"
                                      :required="false"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="txt-light_green">無其他可選擇積分</p>
                                  </template>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <div class="payment_check txt-right cf active">
                    <p class="payment_check_total">
                      總金額 <span class="txt-light_green">{{ priceTotal }}</span> 元
                    </p>
                    <div class="filter_box payment_check_container fright">
                      <Select
                        id="pay_type"
                        v-model="user.paymentType"
                        :item="inputFormat.paymentSelect"
                        :rules="[
                          val => !!val || '必選欄位！',
                        ]"
                      />

                      <!-- 信用卡繳費：綠界金流 -->
                      <button
                        type="button"
                        class="btn p"
                        @click="onSubmit"
                      >前往繳費</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </form>
        </div>

      </section>
    </main>
  </div>
</template>

<script>
import cities from '@/data/cities.json';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import TextInput from '@/components/form/TextInput.vue';
import Radio from '@/components/form/Radio.vue';
import RadioFraction from '@/components/form/RadioFraction.vue';
import CheckboxFraction from '@/components/form/CheckboxFraction.vue';
import Select from '@/components/form/Select.vue';
import { setAxiosHeaders } from '@/boot/axios';
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import {
  idVerify, login, registerAsNonmember, courseCar,
} from '@/lib/http';

export default {
  name: 'RegisterOnlinePayment',
  data() {
    return {
      /**
       * Static Data
       */
      ...RegisterOnlineSubHeader,

      /** 檢驗是否經過身分證字號判別，可能結果有兩種：DB認得 or DB不認得 */
      idCheck: false,

      /** 非會員報名表開關 */
      showUserForm: false,

      /** 購買明細開關 */
      showPaymentDetail: false,

      // switchList: [
      //   {
      //     name: '首次報名',
      //   },
      //   {
      //     name: '已填過報名資料',
      //   },
      // ],
      tableName: '首次報名',
      hospitalAddressErrorMsg: '',
      inputFormat: {
        nameInput: {
          label: '姓名',
          type: 'text',
          placeholder: '請輸入姓名',
          required: true,
        },
        jobsTitleRadio: {
          label: '身份',
          name: 'jobsTitle',
          required: true,
          options: [
            {
              name: '醫師',
              value: '3',
            },
            {
              name: '護理人員',
              value: '4',
            },
          ],
        },
        idNumberInput: {
          label: '身分證字號',
          type: 'text',
          placeholder: '請輸入身分證字號',
          required: true,
        },
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        hospitalIdInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalAddrInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: false,
        },
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            '信用卡',
            // 'ATM轉帳',
            // '超商代碼繳費',
          ],
        },
      },
      tableHead1: '課程名稱',
      tableHead2: '集數',
      tableHead3: '收據抬頭',
      tableHead4: '金額',
      fractionsCollection: [],
      user: {
        type: '2',
        user_type: '',
        realname: '',
        id_number: '',
        phone: '',
        jobs: {
          hospital_name: '',
          hospitalCity: '',
          hospitalArea: '',
          hospitalZipcode: '',
          hospitalAddr: '',
        },
        // memo: {
        //   hospitalCity: '',
        //   hospitalArea: '',
        //   hospitalZipcode: '',
        //   hospitalAddr: '',
        // },
        paymentType: '',
      },

      /** Axios Data */
      level: '',
      idVerified: false,
      tableData: [],
    };
  },
  created() {
    // console.log(this.$cookies.get('courseInCart'));
    this.level = this.$cookies.get('level');
    if (this.hasToken) {
      courseCar({ course: JSON.parse(this.$cookies.get('courseInCart')) }).then((result) => {
        this.tableData = result;
      });
    }
  },
  computed: {
    // ...mapState([
    //   'level',
    // ]),
    ...mapGetters([
      'hasToken',
    ]),
    payId() {
      if (this.user.paymentType === '信用卡') {
        return 1;
      }
      return null;
    },
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.jobs.hospitalCity);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
    priceTotal() {
      let amount = 0;
      this.paymentTarget.forEach((data) => {
        if (data.price) {
          amount += data.price[this.level].price * 1;
        }
      });
      return amount;
    },
    paymentTarget() {
      const target = [];
      this.tableData.forEach((data) => {
        /** 選取免費課程 */
        if (data.pay_type * 1 === 0) {
          target.push(data);
        } else {
          /** 選取符合身份的課程 */
          const test = data.price[this.level].price;
          if (test) {
            target.push(data);
          }
        }
      });
      return target;
    },
    /** 免費課程 */
    boughtFree() {
      const target = [];
      this.tableData.forEach((data) => {
        if (data.bought_completed === null
        && data.price[this.level].price
        && data.price[this.level].price * 1 === 0) {
          target.push(data);
        }
      });
      return target;
    },
    /** 重複購買課程 */
    boughtAlready() {
      const target = [];
      this.tableData.forEach((data) => {
        if (data.bought_completed !== null) {
          target.push(data);
        }
      });
      return target;
    },
    /** 可購買課程 */
    boughtAvailable() {
      const target = [];
      this.tableData.forEach((data) => {
        if (data.bought_completed === null
          && data.price[this.level].price
          && data.price[this.level].price * 1 > 0) {
          target.push(data);
        }
      });
      return target;
    },
  },
  methods: {
    ...mapActions([
      'login',
      'logout',
    ]),
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
    ]),
    isFractionExist(course) {
      const checkArray = [];
      if (course && course.fractions) {
        Object.keys(course.fractions).forEach((key) => {
          if (course.fractions[key] * 1 !== 0) {
            checkArray.push(true);
          }
        });
      }
      if (checkArray.length > 0) {
        return true;
      }
      return false;
    },
    fractionRadio(data) {
      const options = [];
      if (data.fraction_a !== 0) {
        options.push({
          name: 'A類',
          num: data.fraction_a,
          value: 'fraction_a',
        });
      }
      if (data.fraction_b !== 0) {
        options.push({
          name: 'B類',
          num: data.fraction_b,
          value: 'fraction_b',
        });
      }
      return {
        uuid: data.uuid,
        label: '請選擇ＡＢ類積分',
        name: 'fractionRadio',
        options,
      };
    },
    fractionCheckbox(data) {
      return data;
    },
    updateFraction(data) {
      /** 檢查array內的item是否已有重複uuid，若有則僅更新fraction_list */
      for (let i = 0; i < this.fractionsCollection.length; i += 1) {
        const item = this.fractionsCollection[i];
        if (item.uuid === data.uuid) {
          /** checkbox勾選結果 */
          if (typeof data.fraction === 'object') {
            const indexA = item.fraction_list.indexOf('fraction_a');
            const indexB = item.fraction_list.indexOf('fraction_b');
            if (indexA !== -1) {
              item.fraction_list = [...data.fraction, 'fraction_a'];
            }
            if (indexB !== -1) {
              item.fraction_list = [...data.fraction, 'fraction_b'];
            }
            if (indexA === -1 && indexB === -1) {
              item.fraction_list = data.fraction;
            }
            return;
          }
          /** radio勾選結果 */
          if (data.fraction === 'fraction_a' || data.fraction === 'fraction_b') {
            const indexA = item.fraction_list.indexOf('fraction_a');
            const indexB = item.fraction_list.indexOf('fraction_b');
            if (indexA !== -1) {
              item.fraction_list.splice(indexA, 1);
            }
            if (indexB !== -1) {
              item.fraction_list.splice(indexB, 1);
            }
            item.fraction_list.push(data.fraction);
            return;
          }
        }
      }
      /** 若array內無該uuid，則直接新增一個完整的資料架構 */
      if (typeof data.fraction === 'object') {
        this.fractionsCollection.push({
          uuid: data.uuid,
          fraction_list: [...data.fraction],
        });
      } else {
        this.fractionsCollection.push({
          uuid: data.uuid,
          fraction_list: [data.fraction],
        });
      }
    },
    isActive(data) {
      if (data.price) {
        // console.log('price', data.price);
        const level = this.$cookies.get('level');
        // console.log(level);
        const test = data.price[level].price;
        // console.log('test', test);
        if (test === '' || test === null) {
          return true;
        }
        return false;
      }
      return false;
      // if (data.pay_type * 1 === 1) {
      //   const test = data.price[this.level].price;
      //   if (test === '' || test === '0' || test === null) {
      //     return true;
      //   }
      //   return false;
      // }
      // return false;
    },
    restrainContentMap(userType) {
      if (userType * 1 === 1) {
        return '限醫師';
      }
      return '限護理人員';
    },
    // switchTable(name) {
    //   this.tableName = name;
    // },
    userIdVerify() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^FormInput/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        /** 確認使用者身份前，先將目前使用者登出 */
        // this.logout();
        // setAxiosHeaders(null);

        idVerify({
          id_number: this.user.id_number,
        }).then((result) => {
          /** 一但經過user verify API及判定為有經過DB確認身份（但結果可能為"DB認得"或"DB不認得"兩種） */
          this.idCheck = true;
          /** 若DB認得則執行登入流程，並設定token */
          if (result) {
            // console.log('User ID Recognized');
            login({
              id_number: this.user.id_number,
              password: this.user.id_number,
            }).then((res) => {
              this.login(res.data.result.user);
              setAxiosHeaders(this.$cookies.get('token'));
              this.level = this.$cookies.get('level');
            }).then(() => {
              courseCar({ course: JSON.parse(this.$cookies.get('courseInCart')) })
                .then((response) => {
                  this.tableData = response;
                  this.showPaymentDetail = true;
                });
              // console.log('car again!');
            });

          /** 若DB不認得，則開啟非會員註冊表單走註冊流程 */
          } else {
            // console.log('User ID Unknown!');
            this.showUserForm = true;
          }
        });
        // request({
        //   url: '/login',
        //   method: 'post',
        //   data: toFormData({
        //     id_number: this.user.id_number,
        //     password: this.user.id_number,
        //   }),
        // }).then((res) => {
        //   this.login(res.data.result.user);
        //   this.showUserInfo = true;
        // });
        /**
         * 若server不認得user ID，則帶出空表單待填
         */
        // this.showUserInfo = true;
        /**
         * 若server內已有user ID紀錄，則帶出填好的表單及購買清單
         */
        // this.showUserInfo = true;
        // this.userEstablish = true;

        // request({
        //   url: 'API',
        //   method: 'post',
        //   data: toFormData({ id_number: this.user.id_number }),
        // }).then((result) => {
        //   const newbie = true;
        //   if (!newbie) {
        //     this.user.realname = result.realname;
        //     this.user.jobs.title = result.jobs.title;
        //     // this.user.id_number = 'A123456789';
        //     this.user.phone = result.phone;
        //     this.user.hospital_id = result.hospital_id;
        //     this.user.memo.hospitalCity = result.hospitalCity;
        //     this.user.memo.hospitalArea = result.hospitalArea;
        //     this.user.memo.hospitalZipcode = result.hospitalZipcode;
        //     this.user.memo.hospitalAddr = result.hospitalAddr;
        //   }
        //   this.showUserInfo = true;
        // });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.jobs.hospitalCity);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.jobs.hospitalArea);
      if (hospitalAreaIndex >= 0) {
        this.user.jobs.hospitalZipcode = cities[hospitalTownIndex].areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.user.jobs.hospitalZipcode = '';
      }
    },
    resetHospitalSelect() {
      this.user.jobs.hospitalArea = '';
      this.user.jobs.hospitalZipcode = '';
    },
    userInfoSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        // eslint-disable-next-line no-unused-vars
        registerAsNonmember(this.user).then((result) => {
          const resMsg = result.data.message;
          // console.log(resMsg);
          if (result.data.status === false && resMsg.indexOf('已註冊') === -1) {
            this.updateModalInfoContent('身分證字號格式錯誤，請重新輸入');
            this.toggleModalInfo(true);
          } else if (resMsg.indexOf('已註冊') !== -1) {
            this.updateModalInfoContent('該身分證字號已註冊，請回上一步直接登入');
            this.toggleModalInfo(true);
          } else {
            // console.log('User ID Registered');
            login({
              id_number: this.user.id_number,
              password: this.user.id_number,
            }).then((res) => {
              this.login(res.data.result.user);
              setAxiosHeaders(this.$cookies.get('token'));
              this.level = this.$cookies.get('level');
            }).then(() => {
              courseCar({ course: JSON.parse(this.$cookies.get('courseInCart')) })
                .then((response) => {
                  this.tableData = response;
                  // this.showUserForm = false;
                  this.showPaymentDetail = true;
                });
              // console.log('car again!');
            });
            this.showUserForm = false;
            // this.showPaymentDetail = true;
            // this.$forceUpdate();
          }
          // if (result) {
          //   console.log('User ID Registered');
          //   login({
          //     id_number: this.user.id_number,
          //     password: this.user.id_number,
          //   }).then((user) => {
          //     this.login(user);
          //     setAxiosHeaders(this.$cookies.get('token'));
          //   });
          //   this.showUserForm = false;
          //   this.showPaymentDetail = true;
          // } else {
          //   console.log('Register Failed');
          //   this.updateModalInfoContent('身分證字號格式錯誤，請重新輸入');
          //   this.toggleModalInfo(true);
          // }
        });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        this.$cookies.set('paymentTarget', JSON.stringify(this.paymentTarget));
        this.$cookies.set('fractionsCollection', JSON.stringify(this.fractionsCollection));
        this.$cookies.set('payId', JSON.stringify(this.payId));
        this.$cookies.set('boughtFree', JSON.stringify(this.boughtFree));
        this.$cookies.set('boughtAlready', JSON.stringify(this.boughtAlready));
        this.$cookies.set('boughtAvailable', JSON.stringify(this.boughtAvailable));
        this.$router.push({ path: '/register/online/grocery/confirm' });
        // request({
        //   url: 'API',
        //   method: 'post',
        //   data: toFormData({
        //     ...this.user,
        //     paymentTarget: this.paymentTarget,
        //     priceTotal: this.priceTotal,
        //   })
        //     .then(() => {
        //       this.$cookies.remove('courseInCart');
        //       this.$router.push({ path: '/register/online/grocery/success' });
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     }),
        // });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  updated() {
    if (this.user.user_type) {
      const clickTarget = document.getElementById(`${this.user.user_type}`);
      if (clickTarget) {
        clickTarget.click();
      }
    }
  },
  components: {
    PageSubHeader,
    TextInput,
    Radio,
    RadioFraction,
    CheckboxFraction,
    Select,
  },
};
</script>
